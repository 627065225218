<template>
    <div class="m-5 p-3">
        <b-modal title="" id="modal" size="xl" no-close-on-esc hide-footer @hidden="model={}">
            <choose-product></choose-product>
        </b-modal>
        <b-modal title="Edit" id="editModal" no-close-on-esc hide-footer @hidden="model={}">
            <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <div class="row">
                        <div class="col-md-12">
                            <validated-input name="Selling Price" label="Selling Price"
                                             v-model="EditModel.selling_price"
                                             @keypress="isNumber"/>
                        </div>
                        <div class="col-md-12">
                            <validated-input name="Display Price" label="Display Price"
                                             v-model="EditModel.display_price"
                                             @keypress="isNumber"/>
                        </div>
                        <div class="col-md-12">
                            <b-button type="submit" variant="success">
                                Save
                            </b-button>
                        </div>

                    </div>
                </b-form>
            </validation-observer>
        </b-modal>

        <b-card>
            <b-card-title>
                <b-button variant="success" size="sm" class="float-right" @click="openModal">Add Products</b-button>
                <h3>Catalog</h3>
            </b-card-title>
            <div class="responsive-table">
                <vue-table :data="data" :fields="fields" :url="url" ref="table">
                    <template #selling_price="{rowData}">
                        <span v-if="rowData.selling_price" v-html="(rowData.selling_price)/100"></span>
                    </template>
                    <template #image="{rowData}">
                        <img :src="rowData.icon_path" style="max-width: 50px"
                             class="rounded-circle" alt="">
                    </template>
                    <template #action="{rowData}">
                        <b-button-group>
                            <b-button size="sm" variant="info" @click="EditItem(rowData)">Edit</b-button>
                            <b-button size="sm" variant="danger" @click="deleteItem(rowData)">Delete</b-button>
                        </b-button-group>
                    </template>
                </vue-table>
            </div>
        </b-card>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'secure-axios';
import urls from '@data/urls';
import ChooseProduct from '@/views/SuperAdmin/Shops/ShopCatalog/ChooseProduct';

export default {
    name: 'ShopCatalog',
    components: { ChooseProduct },
    data () {
        return {
            product: '',
            model: {
                id: this.$route.params.id,
                name: '',
                description: '',
                category: '',
                price: ''
            },
            EditModel: {
                id: '',
                selling_price: '',
                display_price: ''
            },
            rules: {
                name: {
                    required: true
                },
                description: {
                    required: true
                },
                category: {
                    required: true
                },
                price: {
                    required: true
                }
            },
            // need to update //
            url: urls.shopProduct.shopproductlist + '?id=' + this.$route.params.id,
            data: [],
            fields: [
                {
                    name: 'id',
                    title: 'ID',
                    sortField: 'id'
                },
                {
                    name: 'name',
                    title: 'Name',
                    sortField: 'name'
                },
                {
                    name: 'description',
                    title: 'Description',
                    sortField: ''
                },
                {
                    name: 'category_id',
                    title: 'Category',
                    sortField: 'category'
                },
                {
                    name: '__slot:selling_price',
                    title: 'Price',
                    sortField: 'price'
                },
                {
                    name: '__slot:action',
                    title: 'Action'
                }
            ]
        };
    },

    methods: {
        openModal () {
            this.$bvModal.show('modal');
        },
        openEditModal () {
            this.$bvModal.show('editModal');
        },

        setData () {
            this.$refs.table.refreshTable();
        },
        async loadData () {
            const response = await axios.post(urls.shopProduct.shopproductlist + '?id=' + this.$route.params.id);
            console.log('response:>', response.data);
            this.setData(response);
        },

        async onSubmit () {
            const url = urls.shopProduct.update;
            const response = await axios.form(url, this.EditModel);
            if (response.data.success) {
                this.setData(response);
                this.$bvModal.hide('editModal');
            } else {
                this.$refs.formValidator.setErrors(response.data.errors);
            }
        },
        EditItem (rowData) {
            console.log('rowData', rowData.category.value);
            this.EditModel = {
                id: rowData.id,
                selling_price: (rowData.selling_price) / 100,
                display_price: (rowData.display_price) / 100
            };
            this.openEditModal();
        },
        async deleteItem (rowData) {
            const confirm = await this.$bvModal.msgBoxConfirm('Are you sure ?');
            if (confirm) {
                const params = { id: rowData.id };
                // need to update the url //
                const response = await axios.form(urls.shopProduct.delete, params);
                this.setData(response);
            }
        },
        isNumber: function (evt) {
            evt = (evt) || window.event;
            const charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
                ;
            } else {
                return true;
            }
        }

    }
};

</script>
